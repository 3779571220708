/* === Import template variables === */
@import "./variables";

/* === MDC Button === */
@import "@material/button/mdc-button";
/*=== MDC Card === */
@import "@material/card/mdc-card";
/*=== MDC Checkbox === */
@import "@material/checkbox/mdc-checkbox";
/*=== MDC Chip === */
@import "@material/chips/mdc-chips";
/*=== MDC Datatable === */
@import "@material/data-table/mdc-data-table";
/*=== MDC Dialog === */
@import "@material/dialog/mdc-dialog";
/*=== MDC Drawer === */
@import "@material/drawer/mdc-drawer";
/*=== MDC Elevation === */
@import "@material/elevation/mdc-elevation";
/*=== MDC Fab === */
@import "@material/fab/mdc-fab";
/*=== MDC Floating Label === */
@import "@material/floating-label/mdc-floating-label";
/*=== MDC Form Field === */
@import "@material/form-field/mdc-form-field";
/*=== MDC Icon Button === */
@import "@material/icon-button/mdc-icon-button";
/*=== MDC Image List === */
@import "@material/image-list/mdc-image-list";
/*=== MDC Layout Grid === */
@import "@material/layout-grid/mdc-layout-grid";
/*=== MDC Line Ripple === */
@import "@material/line-ripple/mdc-line-ripple";
/*=== MDC Progress === */
@import "@material/linear-progress/mdc-linear-progress";
/*=== MDC List === */
@import "@material/list/mdc-list";
/*=== MDC Menu === */
@import "@material/menu/mdc-menu";
/*=== MDC Menu Surface === */
@import "@material/menu-surface/mdc-menu-surface";
/*=== MDC Notched outline === */
@import "@material/notched-outline/mdc-notched-outline";
/*=== MDC Radio Button === */
@import "@material/radio/mdc-radio";
/*=== MDC Ripple === */
@import "@material/ripple/mdc-ripple";
/*=== MDC Select === */
@import "@material/select/mdc-select";
/*=== MDC Slider === */
@import "@material/slider/mdc-slider";
/*=== MDC Snackbar === */
@import "@material/snackbar/mdc-snackbar";
/*=== MDC Switch === */
@import "@material/switch/mdc-switch";
/*=== MDC Tab === */
@import "@material/tab/mdc-tab";
/*=== MDC Tab bar === */
@import "@material/tab-bar/mdc-tab-bar";
/*=== MDC Tab Indicator === */
@import "@material/tab-indicator/mdc-tab-indicator";
/*=== MDC Tab scroller === */
@import "@material/tab-scroller/mdc-tab-scroller";
/*=== MDC Textfield === */
@import "@material/textfield/mdc-text-field";
/*=== MDC Theme === */
@import "@material/theme/mdc-theme";
/*=== MDC Top Appbar === */
@import "@material/top-app-bar/mdc-top-app-bar";
/*=== MDC Typography === */
@import "@material/typography/mdc-typography";

// @import "material-components-web/material-components-web";
// @use "./material-components-web";

// /* === MDC Button === */
// @import "@material/button/mdc-button";
// /* === MDC Card === */
// @import "@material/card/mdc-card";
// /* === MDC Checkbox === */
// @import "@material/checkbox/mdc-checkbox";
// /* === MDC Chips === */
// @import "@material/chips/mdc-chips";
// /* === MDC Data Table === */
// @import "@material/data-table/mdc-data-table";
// /* === MDC Dialog === */
// @import "@material/dialog/mdc-dialog";
// /* === MDC Drawer === */
// @import "@material/drawer/mdc-drawer";
// /* === MDC Elevation === */
// @import "@material/elevation/mdc-elevation";
// /* === MDC Fab === */
// @import "@material/fab/mdc-fab";
// /* === MDC Floating Label === */
// @import "@material/floating-label/mdc-floating-label";
// /* === MDC Form Field === */
// @import "@material/form-field/mdc-form-field";
// /* === MDC Grid List === */
// // @import "@material/grid-list/mdc-grid-list";
// /* === MDC Icon button === */
// @import "@material/icon-button/mdc-icon-button";
// /* === MDC Image List === */
// @import "@material/image-list/mdc-image-list";
// /* === MDC Layout Grid === */
// @import "@material/layout-grid/mdc-layout-grid";
// /* === MDC Line Ripple === */
// @import "@material/line-ripple/mdc-line-ripple";
// /* === MDC Linear Progress === */
// @import "@material/linear-progress/mdc-linear-progress";
// /* === MDC List === */
// @import "@material/list/mdc-list";
// /* === MDC Menu === */
// @import "@material/menu/mdc-menu";
// /* === MDC Menu Surface === */
// @import "@material/menu-surface/mdc-menu-surface";
// /* === MDC Notched Outline === */
// @import "@material/notched-outline/mdc-notched-outline";
// /* === MDC Radio === */
// @import "@material/radio/mdc-radio";
// /* === MDC Ripple === */
// @import "@material/ripple/mdc-ripple";
// /* === MDC Select === */
// @import "@material/select/mdc-select";
// /* === MDC Slider === */
// @import "@material/slider/mdc-slider";
// /* === MDC Snackbar === */
// @import "@material/snackbar/mdc-snackbar";
// /* === MDC Switch === */
// @import "@material/switch/mdc-switch";
// /* === MDC Tab === */
// @import "@material/tab/mdc-tab";
// /* === MDC Tab Bar === */
// @import "@material/tab-bar/mdc-tab-bar";
// /* === MDC Indicator === */
// @import "@material/tab-indicator/mdc-tab-indicator";
// /* === MDC Scrollbar === */
// @import "@material/tab-scroller/mdc-tab-scroller";
// /* === MDC Text Field === */
// @import "@material/textfield/mdc-text-field";
// /* === MDC Theme === */
// @import "@material/theme/mdc-theme";
// /* === MDC Top App Bar === */
// @import "@material/top-app-bar/mdc-top-app-bar";
// /* === MDC Typography === */
// @import "@material/typography/mdc-typography";
